.FeatureFrame-header {
    background-color: gainsboro;
    min-height: 12vh;
    display: flex;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.FeatureFrame-body {
    margin-top: 5vh;
}

.FeatureFrame-back-btn {
    min-width: 100px;
}
.modeSelection {
  width: 300px;
  margin-bottom: 20px;
}

.frequencySelection {
  margin-bottom: 15px;
}

.clusterSection {
  height: 150px;
}

.clusterCard {
  padding: 15px 12px;
  border-radius: 8px;
}

.message {
  margin-bottom: 30px;
}

.warning {
  color: #e32636;
}
.progressBarArea {
  margin: 6vh 1vw;
}

.progressBar {
  width: 30vw;
  min-width: 400px;
}

.hint {
  color: #757575;
  padding: 5px;
}

.toneSequenceInput {
  width: 20vw;
  min-width: 300px;
}

.resultContainer {
  height: 150px;
  margin: 3vh 0;
}

.infoCard {
  width: 20vw;
  min-width: 300px;
  height: 100px;
  padding: 15px 10px;
  border-radius: 8px;
} 

.resultCard {
  height: 100px;
}

.configurationCard {
  height: 170px;
}

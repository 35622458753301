.categorySelection {
    width: 300px;
    padding-top: 60px;
    margin-bottom: 20px;
}

.problemSelection {
    width: 300px;
}

.hearingProfileContainer {
    border-radius: 10px;
}

.solutionButton {
    margin-top: 30px;
}

.carouselContainer {
    background-color: bisque;
    height: 180px;
    width: 400px;
    margin: 50px 0;
    border-radius: 10px;
}

.carousel {
    width: 400px;
}

.carousel-indicators {
    display: none;
}

.carouselCaption {
    margin-bottom: 10px;
}

.hearingProfileResult {
    width: 320px;
}

.hearingProfileCard {
    padding: 10px;
}
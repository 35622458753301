.mainContainer {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text {
    color: #0d6efd;
}

.modelSelection {
    width: 300px;
}

.selectionContainer {
    text-align: center;
    margin-bottom: 60px;
}